* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* overflow-y: visible; */
}
body {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
:root {
  scroll-behavior: smooth;
}

.top-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  bottom: auto;
  right: auto;
  background: #6c567b;
  transition: all 0.9s cubic-bezier(0.6, 0.05, 0.4, 1);
  top: 100%;
}
.top-layer--2 {
  background: #393e46;
  transition-delay: 0.6s;
}

.top-layer--3 {
  background: #6c5b7b;
  transition-delay: 0.4s;
}

.top-layer--4 {
  background: #c06c84;
  transition-delay: 0.2s;
}

.top-layer--5 {
  background: #f67280;
  transition-delay: 0.1s;
}

body {
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  background-color: white;
  background-image: url("./assets/Logo/Background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: gradient 15s ease infinite;
  font-family: "Relation De Luxe W05 Regular";
}
@font-face {
  font-family: "Relation De Luxe W05 Script";
  src: url("./Fonts/7183324/b57208dc-0d43-44b5-acf1-719464b668c6.woff2")
      format("woff2"),
    url("./Fonts/7183324/0b873ca5-7428-4d20-9e8b-a26689bf6f85.woff")
      format("woff");
}
@font-face {
  font-family: "Relation De Luxe W05 Regular";
  src: url("./Fonts/7183327/325222bf-1f28-4396-aee8-bac633081455.woff2")
      format("woff2"),
    url("./Fonts/7183327/a26b2d39-a338-48a3-86d5-f45ae3137765.woff")
      format("woff");
}

/* @keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */

.active {
  font-weight: bold;
  color: black;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}
.navbar-toggler-icon {
  color: black;
}
@media only screen and (max-width: 799px) {
  .btn {
    width: 95%;
  }
  .btn-contact {
    width: 100%;
  }
  .links {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 7px;
    margin: 10px;
    overflow: auto;
  }
  .pricing .box.box:hover {
    transform: none;
    box-shadow: 0px 0 30px rgba(255, 255, 255, 0.1);
  }
}

.navbar-brand {
  width: 150px;
  font-size: 24px;
}
.navbar-brand img {
  height: 80px;
  /* width: 80px; */
  /* put value of image height as your need */
  float: left;
  margin-right: 7px;
}

/* Login */

.darken-grey-text {
  color: #2e2e2e;
}
.danger-text {
  color: #ff3547;
}
.default-text {
  color: #2bbbad;
}
.info-text {
  color: #33b5e5;
}
.form-white .md-form label {
  color: #fff;
}
.form-white input[type="text"]:focus:not([readonly]) {
  border-bottom: 1px solid #fff;
  -webkit-box-shadow: 0 1px 0 0 #fff;
  box-shadow: 0 1px 0 0 #fff;
}
.form-white input[type="text"]:focus:not([readonly]) + label {
  color: #fff;
}
.form-white input[type="password"]:focus:not([readonly]) {
  border-bottom: 1px solid #fff;
  -webkit-box-shadow: 0 1px 0 0 #fff;
  box-shadow: 0 1px 0 0 #fff;
}
.form-white input[type="password"]:focus:not([readonly]) + label {
  color: #fff;
}
.form-white input[type="password"],
.form-white input[type="text"] {
  border-bottom: 1px solid #fff;
}
.form-white .form-control:focus {
  color: #fff;
}
.form-white .form-control {
  color: #fff;
}
.form-white textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #fff;
  box-shadow: 0 1px 0 0 #fff;
  color: #fff;
}
.form-white textarea.md-textarea {
  border-bottom: 1px solid #fff;
  color: #fff;
}
.form-white textarea.md-textarea:focus:not([readonly]) + label {
  color: #fff;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: black;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Relation De Luxe W05 Regular";
}

/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
section {
  padding: 20px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-header h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  color: black;
  text-transform: uppercase;
}

.section-header p {
  margin: 10px 0 0 0;
  padding: 10px;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
  color: black;
}

@media (max-width: 768px) {
  .section-header p {
    font-size: 28px;
    line-height: 32px;
  }
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: black;
  min-height: 40px;
  margin-top: 82px;
  color: #fff;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 57px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 500;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol a {
  color: #fff;
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: black;
  content: "/";
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: black;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: black;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
}

.header.header-scrolled {
  background: #fff;
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header .logo {
  line-height: 0;
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Relation De Luxe W05 Regular";
  margin-top: 3px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Relation De Luxe W05 Regular";
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: white;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: black;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: white;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: black;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: white;
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #012970;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #4154f1;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: white;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/* Test */

.lightbox {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 2px;

  /* Image border */
  border: none;
  box-shadow: 0px 0px 12px 0px black;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 2px;

  /* Background color behind image.
     This is visible during transitions. */
  background-color: transparent;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  /* background: url(../images/loading.gif) no-repeat; */
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  /* background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); */
}

.lb-prev,
.lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  /* background: url(../images/prev.png) left 48% no-repeat; */
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 0;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  /* background: url(../images/next.png) right 48% no-repeat; */
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 0;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  zoom: 1;
  width: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #cccccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  padding-top: 2px;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 11pt;
  font-weight: normal;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  /* background: url(../images/close.png) top right no-repeat; */
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
.comic {
  width: 20% !important;
  /* height: 50rem; */
  box-shadow: 0px 0px 6px 1px #212121;
}
#photos-comic {
  line-height: 5vw;
  margin: 2vw;
  -webkit-column-count: 2;
  -webkit-column-gap: 0px;
  -moz-column-count: 2;
  -moz-column-gap: 0px;
  column-count: 2;
  column-gap: 2vw;
}

#parent {
  display: grid;
  width: 100%;
  height: 100%;
  grid-column-end: auto;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  padding-left: 30rem;
  padding-right: 30rem;
  /* grid-gap: 20px; */
}

#comic-img {
  width: 70%;
  grid-row: 1 / 3;
  grid-column: 1 / 1;
}
#photos {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 3;
  background-color: rgba(249, 249, 249, 0.496);
  /* line-height: 7vw; */
}
#photos img {
  width: 100% !important;
  height: auto !important;
}
#photos-front {
  /* line-height: 5vw;
  margin: 0.5vw; */
  -webkit-column-count: 1;
  -webkit-column-gap: 0px;
  /* -moz-column-count: 3; */
  -moz-column-gap: 0px;
  column-count: 6;
  column-gap: 1vw;
  padding-left: 20rem;
  padding-right: 20rem;
}
#photos-front img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto;
  box-shadow: 0px 0px 6px 1px #212121;
}

@media (max-width: 1200px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 7vw;
  }
  #photos img {
    width: 100% !important;
    height: auto !important;
  }
  #photos-front {
    /* line-height: 5vw;
    margin: 0.5vw; */
    -webkit-column-count: 1;
    -webkit-column-gap: 0px;
    /* -moz-column-count: 3; */
    -moz-column-gap: 0px;
    column-count: 6;
    column-gap: 1vw;
    padding-left: 10rem;
    padding-right: 10rem;
  }
  #photos-front img {
    /* Just in case there are inline attributes */
    width: 100% !important;
    height: auto;
    box-shadow: 0px 0px 6px 1px #212121;
  }
  #photos-comic {
    -moz-row-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 7vw;
  }
  #photos-comic img {
    width: 100% !important;
    height: auto !important;
    display: block;
  }
  body {
    background-size: cover;
  }
  #parent {
    display: grid;
    width: 100%;
    height: 100%;
    /* grid-column-end: auto; */
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    gap: 1;
    padding-left: 10rem;
    padding-right: 10rem;
    /* justify-content: center; */
    /* grid-gap: 20px; */
  }
  #comic-img {
    width: 70%;
    grid-row: 1 / 2;
    grid-column: 1 / 1;
  }
}
@media (max-width: 1000px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 7vw;
  }
  #photos img {
    width: 100% !important;
    height: auto !important;
  }
  #photos-comic {
    -moz-row-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 7vw;
  }
  #photos-comic img {
    width: 100% !important;
    height: auto !important;
    display: block;
  }
  #photos-front {
    /* line-height: 5vw;
    margin: 0.5vw; */
    -webkit-column-count: 1;
    -webkit-column-gap: 0px;
    /* -moz-column-count: 3; */
    -moz-column-gap: 0px;
    column-count: 2;
    column-gap: 2vw;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  #photos-front img {
    /* Just in case there are inline attributes */
    width: 100% !important;
    height: auto;
    box-shadow: 0px 0px 6px 1px #212121;
  }
  .hidden {
    display: none;
  }
  body {
    background-size: cover;
  }

  #parent {
    display: grid;
    width: 100%;
    height: 100%;
    /* grid-column-end: auto; */
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    gap: 1;
    padding-left: 6rem;
    padding-right: 6rem;
    /* justify-content: center; */
    /* grid-gap: 20px; */
  }
  #comic-img {
    width: 90%;
    grid-row: 1 / 2;
    grid-column: 1 / 1;
  }
}
@media (max-width: 800px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 7vw;
  }
  #photos img {
    width: 100% !important;
    height: auto !important;
  }
  #photos-comic {
    -moz-row-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 7vw;
  }
  #photos-comic img {
    width: 100% !important;
    height: auto !important;
    display: block;
  }
  #photos-front {
    /* line-height: 5vw;
    margin: 0.5vw; */
    -webkit-column-count: 1;
    -webkit-column-gap: 0px;
    /* -moz-column-count: 3; */
    -moz-column-gap: 0px;
    column-count: 2;
    column-gap: 2vw;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  body {
    background-size: cover;
  }

  #parent {
    display: grid;
    width: 100%;
    height: 100%;
    /* grid-column-end: auto; */
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    gap: 1;
    /* justify-content: center; */
    /* grid-gap: 20px; */
    padding-left: 2rem;
    padding-right: 2rem;
  }
  #comic-img {
    width: 90%;
    grid-row: 1 / 2;
    grid-column: 1 / 1;
  }
}
@media (max-width: 600px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 7vw;
  }
  #photos img {
    width: 100% !important;
    height: auto !important;
  }
  #photos-comic {
    -moz-row-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    line-height: 7vw;
  }
  #photos-comic img {
    width: 100% !important;
    height: auto !important;
    display: block;
  }
  body {
    background-size: cover;
  }
  .row {
    display: flex;
  }

  #page-comic {
    max-width: 50%;
    height: auto;
    width: auto\9;
  }
  #page-comicFinal {
    max-width: 50%;
    height: auto;
    width: auto\9;
  }
  .album-web {
    /* background: rgb(255, 251, 251); */
    /* width: 700px; */
    /* padding: 2rem; */
  }
  #parent {
    display: grid;
    width: 100%;
    height: 100%;
    /* grid-column-end: auto; */
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    gap: 1;
    /* justify-content: center; */
    /* grid-gap: 20px; */
  }
  #comic-img {
    width: 90%;
    grid-row: 1 / 2;
    grid-column: 1 / 1;
  }
}
#album-web-id {
  text-align: center;
}

.album-web {
  background: rgb(255, 251, 251);
  text-align: center;
}

.page {
  box-shadow: 0 1.5em 3em -1em rgb(70, 69, 69);
}

.cover {
  background-color: white;
  box-shadow: 0 1.5em 3em -1em rgb(70, 69, 69);
}

.btn-book,
.form-control {
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  appearance: none;
  font-size: 1em;
  line-height: 1.2;
  padding: 0.5em var(--padding-x);
  border-width: 2px;
  border-style: solid;
}
.btn {
  /* background-color: aquamarine; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  --padding-x: 1.2em;
  border-color: transparent;
}
.form-control {
  --padding-x: 0.5em;
}

input {
  text-align: center;
}

.formContainer {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#page-comic {
  max-width: 100%;
  height: auto;
  width: auto\9;
}
